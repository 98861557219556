span.MuiTypography-root.MuiTypography-body1.MuiListItemText-primary.css-10hburv-MuiTypography-root {
    font-family: 'Cairo';
}
/* SideBar.css */

/* SideBar.css */

/* SideBar.css */

.MuiDrawer-paper {
    border: none !important;
    box-shadow: none !important;
    background-color: #fcfdfd !important;
  }
  
  .MuiListItem-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 16px;
    border-radius: 8px;
  }
  
  .MuiListItemIcon-root {
    min-width: 0;
    margin-left: 8px;
  }
  
  .MuiListItemText-root {
    margin: 0;
  }
  
  .MuiListItem-button:hover {
    background-color: #6fd943 !important;
    border-radius: 8px;
  }
  
  .Mui-selected, .Mui-selected:hover {
    background-color: #6fd943 !important;
    border-radius: 8px;
  }
  