
*{
    padding: 0;
    margin: 0;
}

body{
    
    position: relative;
}
.main{

    display: flex;
    justify-content: center;
    background-image: url("https://i.ibb.co/MnQKcCq/mainbackgroung.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100vh;
}

.col {
    float: left;
    width: 33.33%;
    height: 100vh;
    padding:0px
}
.top-left{
  
    width: 100%;
    height: 65vh;
}

.LEFT-TOP-IMG ,.RIGHT-TOP-IMG{
    width: 65%;
    height: 65%;
    padding: 36px;
}

.top-left-img ,.top-right-img{
    display: flex;
    justify-content: center;
}
.top-left-img {
    background-image: url("https://i.ibb.co/CWh7WCM/0-1.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    width: 100%;
    height: 45vh;
}
.top-right{

    width: 100%;
    height: 65vh;
}
.top-right-img{
    background-image: url("https://i.ibb.co/dt4NPsL/02.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    width: 100%;
    height: 45vh;
}
.persons img ,.persons2 img {
    width: 20% !important;
    
}
.persons ,.persons2 {position: relative;}

.persons ,.persons2{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
}


.persons img:nth-of-type(1) {
    margin-top: -111px;
    margin-left: -65px;
}
.persons img:nth-of-type(2) {
    margin-top: -40px;
}
.persons img:nth-of-type(3) {
    margin-top: -5px;
    
}
.persons2 img:nth-of-type(1) {
    margin-top: -12px;
    margin-left: 50px;
}
.persons2 img:nth-of-type(2) {
    margin-top: -30px;
}
.persons2 img:nth-of-type(3) {
    margin-top: -100px;
}



.container {
    width: 55%;
    height: 31vh;
    background-image: url("https://i.ibb.co/SDgYrgk/news-bg-1.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.our-news ,.our-managers{
    position: relative;
    display: flex;
    justify-content: center;
    height: 35vh;
  
}
.container img {
    width: 29%;
}

.container p {
    color: #D0AA7D;
    font-size: 15px;
    padding: 10px 47px 10px 33px;
    text-align: right;
}


.container-manager {
    width: 70%;
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    gap: 20px;
    margin-top: 50px;
}


.ceo ,.gm{
    
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.ceo img:nth-of-type(1) {
    width: 45%;
}
.ceo img:nth-of-type(2) {
    width: 100%;
    padding-bottom: 20px;
}
.ceo img:nth-of-type(3) {
    width: 100%;
}

.gm img:nth-of-type(1) {
    width: 45%;
    padding-bottom: 10px;
}
.gm img:nth-of-type(2) {
    width: 100%;
    padding-bottom: 16px;
}
.gm img:nth-of-type(3) {
    width: 100%;
}



.social {
    width: 55%;
    height: 24vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.social img {
    width: 10%;
}

.col.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
}



.container-design {
    width: 75%;
    height: 350vh;
    background-image: url("https://i.ibb.co/Yy4g9RN/2-1.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.container-design-img {
    width: 35%;
    position: absolute;
    height: 11vh;
    display: flex;
    flex-direction: column;

}

.container-design-img img {
    height: 50vh;
    width: 47%;
    margin: auto;
  
}

img.daad {
    margin-top: 30px;
    width: 60%;
}

img.clock {
    width: 30%;
    padding-bottom: 6px;
    margin-top: -50px;
}

img.welcome {
    width: 50%;
    padding: 5px;
}
img.text {
    width: 50%;
    height: 25vh;
    padding: 5px;
}

img.another {
    height: 10vh;
    width: 35%;
}